<template>
  <div>
    <div class="pb-3">
      <!-- create button -->
      <b-button v-has-permission="'DRIVER_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('LIST_ADD_LABEL', {catalog: $t('DRIVER')})  | capitalize }}</span>
      </b-button>
      <!-- create button -->

      <!-- refresh button -->
      <b-button class="ml-2" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL')  | capitalize }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
      </b-button>
      <!-- clear button -->

      <!-- Excel export -->
      <download-excel class="ml-2 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.module"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize }}
      </download-excel>
      <!-- Excel export -->
    </div>
    <div>
      <!-- List page filter -->
      <driver-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter"
                          v-model="filter"></driver-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <!-- table -->
    <b-table
        id="driver-table"
        ref="driversTable"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :tbody-tr-class="rowClass"
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('DRIVERS')})  | capitalize }}</strong>
        </div>
      </template>

      <!-- employeeNumber -->
      <template #head(employeeNumber)="">
        <span>{{ labels.employeeNumber | capitalize }}</span>
      </template>

      <template #cell(employeeNumber)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.employeeNumber }}</span>
        </div>
      </template>
      <!-- employeeNumber -->

      <!-- fullName -->
      <template #head(fullName)="">
        <span>{{ labels.fullName | capitalize }}</span>
      </template>

      <template #cell(fullName)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.fullName }}</span>
        </div>
      </template>
      <!-- fullName -->

      <!-- position -->
      <template #head(position)="">
        <span>{{ labels.position | capitalize }}</span>
      </template>

      <template #cell(position)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.position }}</span>
        </div>
      </template>
      <!-- position -->

      <!-- company -->
      <template #head(company)="">
        <span>{{ labels.company | capitalize }}</span>
      </template>

      <template #cell(company)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.company }}</span>
        </div>
      </template>
      <!-- company -->

      <!-- phone -->
      <template #head(phone)="">
        <span>{{ labels.phone | capitalize }}</span>
      </template>

      <template #cell(phone)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.phone }}</span>
        </div>
      </template>
      <!-- phone -->

      <!-- driverType -->
      <template #head(driverType)="">
        <span>{{ labels.driverType | capitalize }}</span>
      </template>

      <template #cell(driverType)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ $t(getDriverType(+data.item.driverType)) | capitalize }}</span>
        </div>
      </template>
      <!-- driverType -->

      <!-- inDate -->
      <template #head(inDate)="">
        <span>{{ labels.inDate }}</span>
      </template>

      <template #cell(inDate)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.inDate | date }}</span>
        </div>
      </template>
      <!-- inDate -->

      <!-- outDate -->
      <template #head(outDate)="">
        <span>{{ labels.outDate }}</span>
      </template>

      <template #cell(outDate)="data">
        <div style="text-align: left; vertical-align: center">
          <span v-if="data.item.outDate">{{ data.item.outDate | date }}</span>
          <span v-else></span>
        </div>
      </template>
      <!-- outDate -->

      <template #head(enabled)="">
        <span>{{ labels.enabled  | capitalize }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'DRIVER_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'DRIVER_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.label)">{{ $t('LIST_DELETE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'DRIVER_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.label)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'DRIVER_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.label)" v-else>
              {{ $t('LIST_ENABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <!-- table -->
    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="driver-table">
          </b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {DateFilter, Form, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import Drivers from "@/drivers";
import eventBus from "@/events";
import DriverListFilter from "@/drivers/DriverListFilter";
import PageSize from "@/components/PageSize";
import moment from "moment";

export default {
  name: "DriverList",
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  components: {PageSize, DriverListFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage, DateFilter],
  mounted() {
    eventBus.$on('LIST_COUNT_DRIVER_EVENT', (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.license === '' || (item.license && moment(item.dueDate).date() < new Date())) return 'table-danger'
    },
    getDriverType(type) {
      return type === 0 ? 'DRIVER_TYPE_EMPLOYEE' : 'DRIVER_TYPE_ASSOCIATED'
    }
  },
  data() {
    return {
      labels: {
        employeeNumber: this.$t('DRIVER_EMPLOYEE_NUMBER_LABEL'),
        company: this.$t('DRIVER_COMPANY_LABEL'),
        fullName: this.$t('DRIVER_FULL_NAME_LABEL'),
        phone: this.$t('DRIVER_PHONE_LABEL'),
        position: this.$t('DRIVER_POSITION_LABEL'),
        driverType: this.$t('DRIVER_DRIVER_TYPE_LABEL'),
        inDate: this.$t('DRIVER_IN_DATE_LABEL'),
        outDate: this.$t('DRIVER_OUT_DATE_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        module: this.$t('DRIVERS'),
        excel: this.$t('DRIVERS') + '.xls',
      },
      registerPage: 'DRIVER_REGISTER_TITLE',
      editPage: 'DRIVER_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    controller() {
      return Drivers;
    },
    fields() {
      return [
        {key: 'employeeNumber', sortable: true, tdClass: 'customTd'},
        {key: 'company', sortable: true, tdClass: 'customTd'},
        {key: 'fullName', sortable: true, tdClass: 'customTd'},
        {key: 'phone', sortable: true, tdClass: 'customTd'},
        {key: 'position', sortable: true, tdClass: 'customTd'},
        {key: 'driverType', sortable: true, tdClass: 'customTd'},
        {key: 'inDate', sortable: true, tdClass: 'customTd'},
        {key: 'outDate', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'enabledTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    tableRef() {
      return this.$refs.driversTable;
    },
    excelFields() {
      const fields = {};
      fields[this.labels.photo] = 'photo';
      fields[this.labels.employeeNumber] = 'employeeNumber';
      fields[this.labels.name] = 'name';
      fields[this.labels.dateOfBirth] = 'dateOfBirth';
      fields[this.labels.nationality] = 'nationality'
      fields[this.labels.fatherLastName] = 'fatherLastName'
      fields[this.labels.placeOfBirth] = 'placeOfBirth'
      fields[this.labels.sex] = 'sex'
      fields[this.labels.motherLastName] = 'motherLastName'
      fields[this.labels.civilStatus] = 'civilStatus'
      fields[this.labels.phone] = 'phone'
      fields[this.labels.address] = 'address'
      fields[this.labels.street2] = 'street2'
      fields[this.labels.zipCode] = 'zipCode'
      fields[this.labels.curp] = 'curp'
      fields[this.labels.license] = 'license'
      fields[this.labels.idNumber] = 'idNumber'
      fields[this.labels.driverType] = 'driverType'
      fields[this.labels.rfc] = 'rfc'
      fields[this.labels.expeditionDate] = 'expeditionDate'
      fields[this.labels.nss] = 'nss'
      fields[this.labels.paymentType] = 'paymentType'
      fields[this.labels.dueDate] = 'dueDate'
      fields[this.labels.position] = 'position'
      fields[this.labels.document1] = 'document1'
      fields[this.labels.document2] = 'document2'
      fields[this.labels.document3] = 'document3'
      fields[this.labels.document4] = 'document4'
      fields[this.labels.document5] = 'document5'
      fields[this.labels.document6] = 'document6'
      fields[this.labels.document7] = 'document7'
      fields[this.labels.document8] = 'document8'
      fields[this.labels.document9] = 'document9'
      fields[this.labels.document10] = 'document10'
      fields[this.labels.cronicDesease] = 'cronicDesease'
      fields[this.labels.inDate] = 'inDate'
      fields[this.labels.outDate] = 'outDate'
      return fields;
    }
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
